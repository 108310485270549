<template>
  <ListRow>
    <td>
      <span :class="['badge_state', rowData.noticeType === 'M' ? 'on' : '']">{{
        noticeTypeText
      }}</span>
    </td>
    <td class="td_ellip" :data-ellip="rowData.title">
      <!-- 상태값이 고정일 경우 link_point 클레스 추가 -->
      <!-- <router-link :to='toPath' :class="[ 'link_subject', rowData.fixYn === 'Y' ? 'link_point' : '' ]" :data-ellip="rowData.title"> -->
      <router-link
        :to="toPath"
        :data-ellip="rowData.title"
        :class="[
          'link_subject',
          rowData.fixYn === 'Y' ? 'link_point' : '',
          rowData.fixYn == 'Y' && rowData.noticeType == 'M' ? 'link_bold' : '',
        ]"
        v-html="rowData.title"
      />
    </td>
    <td>{{ rowData.name }}</td>
    <td>{{ regDate }}</td>
  </ListRow>
</template>

<script>
import ListRow from "@/components/shared/ListRow";
import ListLineMixin from "@/mixins/ListLineMixin";
import { makePathWithQuery } from "@/utils/urlUtils";

export default {
  name: "NoticeListLine",
  components: {
    ListRow,
  },

  mixins: [ListLineMixin],
  computed: {
    toPath() {
      const { seq } = this.rowData;
      const path = makePathWithQuery(this.$routerPath.SYSTEM_NOTICE_VIEW, seq, this.$route.query);
      return path;
    },
    noticeTypeText() {
      switch (this.rowData.noticeType) {
        case "M":
          return "메인 공지";
        case "D":
          return "일반";
      }
      return "";
    },
  },
};
</script>
