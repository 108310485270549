<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">공지사항</h2>
      </div>
      <div class="body_section">
        <NoticeListFilterBar
          :statusList="STATUS_LIST"
          :keywordDatas="KEYWORD_DATAS"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultStateId="defaultStateId"
          :defaultDates="defaultDates"
          @onClickSearch="onClickSearch"
        />

        <TableHead>
          <template v-slot:headLeft>
            <button
              v-if="isShowAccessBtn(menuId.SYSTEM_NOTICE_WRITE)"
              type="button"
              class="btn_tertiary btn_medium"
              @click="onClickAdd"
            >
              <span class="ico_purchase ico_cross_w" />신규 등록
            </button>
          </template>
          <template v-slot:headRight>
            <SelectBoxPageRange
              :currentMaxRowCount.sync="size"
              @update:currentMaxRowCount="onChangeRowCount"
            />
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col />
            <col style="width: 172px" />
            <col style="width: 244px" />
          </template>
          <template v-slot:tr>
            <th>구분</th>
            <th>제목</th>
            <th>등록자</th>
            <th>공지 등록일</th>
          </template>
          <template v-slot:row="slotProps">
            <NoticeListLine :rowData="slotProps.rowData" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="4" class="td_empty">공지가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import NoticeListFilterBar from "@/components/admin/system/notice/list/NoticeListFilterBar";
import TableHead from "@/components/shared/TableHead";
import ListPage from "@/components/shared/ListPage";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import NoticeListLine from "@/components/admin/system/notice/list/NoticeListLine";

import { dateToString, stringToDate } from "@/utils/dateUtils";
import LocalStorageManager from "@/LocalStorageManager";

import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

export default {
  components: {
    PageWithLayout,
    NoticeListFilterBar,
    TableHead,
    ListPage,
    SelectBoxPageRange,
    NoticeListLine,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.NOTICE,
      STATUS_LIST: [
        { code: "0", name: "전체", desc: "전체" },
        { code: "D", name: "일반", desc: "일반" },
        { code: "M", name: "메인 공지", desc: "메인 공지" },
      ],
      KEYWORD_DATAS: [
        { id: "title", text: "제목" },
        { id: "contents", text: "내용" },
      ],
    };
  },
  beforeMount() {
    const params = this.checkUrlQuery();

    // this.$store.dispatch( ANNOUNCE_LIST_ACTION ).then( () => {
    this.getDataInit(params);
    // } );
  },

  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.KEYWORD_DATAS);

      const objDates = this.checkQueryDates(query, "regDate1", "regDate2");

      // 공지 사항은 status가 아닌 noticeType으로 하는 것 같다.
      // const objStatus = this.checkQueryStatus(query);
      const objStatus = this.checkQueryStatusNotice(query);

      // const objCateNum = this.checkQueryCateNum(query);

      return Object.assign({}, objPaging, objKeyword, objDates, objStatus);
    },
    checkQueryStatusNotice(query) {
      const { noticeType } = query;

      if (!noticeType) return {};

      // 전체일 때는 안보내야하는 것 같다...
      if (noticeType === 0 || noticeType === "0") return {};

      this.defaultStateId = noticeType;

      return { noticeType: noticeType };
    },
    getFilterbarParams(objData) {
      const {
        keywordSelectedId,
        keywordInputText,
        startDt,
        endDt,
        status,
        // category0, category1, category2,
      } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["regDate1"] = startDt;
        params["regDate2"] = endDt;
      }

      if (status) {
        // params.status = status;

        // 공지 사항은 status가 아닌 noticeType으로 하는 것 같다.
        params.noticeType = status;
      }

      // const cateNum = category2 || category1 || category0;
      // if( cateNum ) {
      //   params.cateNum = cateNum;
      // }

      return params;
    },
    onClickAdd() {
      this.goWrite();
    },
    async goWrite() {
      this.$router.push({
        path: this.$routerPath.SYSTEM_NOTICE_WRITE,
      });
    },
  },
};
</script>
